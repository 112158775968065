import "../App.css";
import { Progress } from "../components/progress/progress.jsx";

export const ProgressPage = () => {
  return (
    <div className="levelsprogress">
      <Progress />
    </div>
  );
};
