export const rankRequirements = {
  Bronze: 9,
  Silver: 13,
  Gold: 7,
  Emerald: 5,
  Ruby: 4,
  Diamond: 3,
  Amethyst: 2,
  Legend: 1,
  Bonus: 12,
};

export const levels = [
  {
    Bronze: [
      "Corrosive Flower (59843187)",
      "DefThlow (31921720)",
      "Answering Allah (60459825)",
      "SAmuel Koch Chal (48812442)",
      "Douglas (57413460)",
      "Turska (62619237)",
      "Challenge EX (34191084)",
      "cCc (49725435)",
      "i dont know (64910644)",
      "Deadly Chamber (58527856)",
      "wiener chaleng (48878216)",
      "Marjorie Husted (45756924)",
      "da share z0ne (67589390)",
      "Rektumi (59612157)",
      "Kadra (61839007)",
    ],
    Silver: [
      "Funk God (46570823)",
      "1 2 1 1p (66767875)",
      "Chall I (62976320)",
      "Aftermath v2 wave (59297291)",
      "taste beat penetrate (63103470)",
      "Galaxy Collapse Wave (67149625)",
      "Planned Parenthood (62243077)",
      "UBaSUNhW (59910938)",
      "Pennutoh Challenge (64206853)",
      "liverpool (44664220)",
      "Deterioration (50676530)",
      "hard wave thingy (52107163)",
      "Applesauce (62990834)",
      "Zone Wave (63116711)",
      "Not The Golden Wave (63459575)",
      "your mom challenge (62705568)",
      "Sorrow Chamber (50541459)",
      "Vain (60050144)",
      "pain (64144943)",
      "Yubin (65452957)",
      "DIAMOND DANCE (52648603)",
      "Tuvalu (64537501)",
    ],

    Gold: [
      "Magic Touch (62413539)",
      "Africa Chamber (56446182)",
      "Raw Sausage so tasty (56279405)",
      "D minor (65139499)",
      "Masochist II (64319423)",
      "Masochist (64319413)",
      "Bioody Knuckles (63369237)",
      "wave TECHNIC (64754276)",
      "Carbon Commination (62308493)",
      "Coggers (64017356)",
      "No Rider (64048771)",
    ],

    Emerald: [
      "The Acro Challenge (46650270)",
      "D (55637201)",
      "Nauru (63907905)",
      "Techno Love (64132218)",
      "Slightly buffed Math (66158978)",
      "bedrock dance (62894149)",
      "Superr Challenge (47354575)",
      "S M I L E (63329754)",
      "Agile (64697883)",
    ],

    Ruby: [
      "Sakupen Circles 66 p (58004788)",
      "Vanuatu (64755953)",
      "Shock (44813027)",
      "Virulent Verge (66663246)",
      "Sheol Death (62076892)",
      "Distorted (67600557)",
      "Craven Soul (67862379)",
      "Toxic Blades (57696990)",
    ],

    Diamond: [
      "Flutterwonder (65496433)",
      "Lost Sense (71530517)",
      "Apotheosis (76663114)",
      "Delusion (65348932)",
      "Deorum (55392313)",
      "Sadism (64919670)",
    ],

    Amethyst: [
      "mystic haze (73332806)",
      "lightposts (73199960)",
      "Falling Illusion (50542904)",
      "Stercore (69718968)",
      "SADOMASOCHISM (76213334)",
      "Oblivers ",
    ],

    Legend: [
      "Illusion of Hell (38230726)",
      "VSC (63006314)",
      "Tremor (79823052)",
      "CorroZ B (67044214)",
    ],

    Bonus: [
      "A B C D E (47096541)",
      "NASTY (59648477)",
      "Madochist (64948235)",
      "Glow Hell (64594657)",
      "kugeldom challenge (66748742)",
      "Action (65480542)",
      "8063 (32714994)",
      "Penitence (68683750)",
      "coughy bean (65796935)",
      "I see (60690548)",
      "Samsung Galaxy Foldm (65538046)",
      "Vanuatu Old (66986269)",
    ],

    // "DLC Challenges: Pack I 🌞": [
    //   "Transient (69272418)",
    //   "Viridian (67058715)",
    //   "GDSR Challenge (68073068)",
    //   "Gelp (68128541)",
    //   "Despair (59183313)",
    //   "Card Console (68719124)",
    //   "Lurox Challenge (56535640)",
    //   "whale TECHNIC (69251403)",
    //   "The Final Sunset (68699690)",
    //   "Jcak Chall (67924463)",
    //   "Cold Water (67545385)",
    //   "Exploring the Sunset (72392678)",
    //   "Scarlet Moon (60198785)",
    //   "Naufira (66207085)",
    //   "STTBG (67835205)",
    //   "R (67488706)",
    //   "Crimson Christmas (66767393)",
    //   "Vandal (67579402)",
    //   "Dissipate (69032837)",
    //   "Radiation (66200701)",
    //   "Illusion (68870869)",
    //   "Flyers (68486597)",
    // ],

    // "DLC Challenges: Pack II 🍂": [
    //   "Happy Chamber (56491894)",
    //   "Halloween Chall (75354898)",
    //   "aAa (62067491)",
    //   "Gas Chamber (50533635)",
    //   "Silver Cascades (72936013)",
    //   "toxicity (67938608)",
    //   "Homo Haha 2 (45417766)",
    //   "Cragley Chamber (62250556)",
    //   "Windpipe Blockage (59510706)",
    //   "Ghostly Chamber (61524563)",
    //   "Eternal Marigold (67628887)",
    //   "Wave Challenge 5 (46016708)",
    //   "waveywaveywaveywavey (72467954)",
    //   "betty crocker (41560546)",
    //   "vVv (62524548)",
    //   "Creeper challenge (24426577)",
    //   "Libra (66547350)",
    //   "Lazar Adriana (72895834)",
    //   "Entire List L (67913492)",
    //   "Jebaiting Jehova (62625892)",
    //   "haydendom 2 (67278170)",
    //   "Orange Chicken (68609215)",
    //   "DeathC Wave Buff (38164910)",
    //   "Sunken (72831058)",
    //   "Schabernack Chamber (75010253)",
    //   "DNTLKBCK (71665702)",
    //   "Summoning Satan (72407461)",
    //   "Apply for Staff (68923122)",
    //   "Cosmic Euphoria (73094292)",
    //   "no name (67001687)",
    //   "GOODBYE MOONMEN (64967581)",
    //   "personal wave chall (72852300)",
    //   "Kristina Vogel (55276699)",
    //   "Wave of Gods (55191746)",
    //   "Doggie Challenge (63801733)",
    //   "Punker Hill (69693774)",
    //   "Meliorism (68937435)",
    //   "Repentance (53005605)",
    //   "Ncat Chall 3 (74031209)",
    //   "OG Bobby Johnson (60239461)",
    //   "wave challenge lol 4 (61217607)",
    //   "GDSR Challenge 2 (71618257)",
    //   "Conundrum (75359883)",
    //   "WoF (64691287)",
    //   "What You Love (71538525)",
    //   "Lime Chimichanga (63668200)",
    //   "Wave Maestro V (65462010)",
    //   "Fikke Stro (73290360)",
    //   "Sideflip II (67669628)",
    //   "Tuliva (65951885)",
    //   "Extremely Tartarus (69774446)",
    //   "ALL EYES ON ME (74131382)",
    //   "sado ripoff (73295611)",
    //   "Skribbl Challenge (68531529)",
    //   "OXYGEN REDUX (65583184)",
    //   "frown (72899127)",
    //   "Ravioli (67211434)",
    //   "Vesivius (70851479)",
    //   "Nino Nakano (69910353)",
    //   "Soccermoms (70444531)",
    //   "Baphomet (70171756)",
    //   "NITROGEN REDUX (67201478)",
    // ],

    // "DLC Challenges: Pack III ❄": [
    //   "VIP Challenge (78528931)",
    //   "Observatory (75873131)",
    //   "Something (71835450)",
    //   "Sandy Chamber (61514366)",
    //   "ise (72794284)",
    //   "sno (61460345)",
    //   "Merry X Challenge (76878758)",
    //   "nfl the movie 359 (76588930)",
    //   "Zamasu Chall 1 (85825780)",
    //   "Mythix Challenge 2 (71257242)",
    //   "Necromantic Forest (69462216)",
    //   "Cronus 1 (76456892)",
    //   "Jagdwurst (61907001)",
    //   "Bloodshot (70358595)",
    //   "pwc 2 (76961579)",
    //   "Sausage for dinner (52570164)",
    //   "Wilted Forest (76078317)",
    //   "Benedikt Chall 1 (84186901)",
    //   "venom eyes (79425874)",
    //   "Hushed Meadows (70358547)",
    //   "Klad (86500111)",
    //   "Yossarian Challenge (69113568)",
    //   "Wave Challenge (62940205)",
    //   "ipanema (87880266)",
    //   "venom eyes II (79444759)",
    //   "Harrier Strike (87840860)",
    //   "Bound (77452606)",
    //   "flower of truth (87472812)",
    //   "ChristMYS (86868291)",
    //   "above the hills (87723553)",
    //   "xmas challenge (86883381)",
    //   "Turbulent (81361397)",
    //   "Extreme Challenge (78023449)",
    //   "Vicious End (79920346)",
    //   "Suetistic (76235907)",
    //   "Last Minute (67606525)",
    //   "Spike Depression (73149969)",
    //   "NB12 (84011110)",
    //   "Dissonance (78512007)",
    //   "Serenity (83167733)",
    //   "Cooks (81023078)",
    //   "Dazed (74226067)",
    // ],
  },
];
